.frame {
	position: relative;
	width: 60px;
	height: 60px;
	overflow: hidden;
	background: white;
	color: #333;
}

.microphone {
	position: absolute;
	width: 60px;
	height: 60px;
}

.circle {
	position: absolute;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	border:3px solid teal;
}

.mic {
	position: absolute;
	width: 24px;
	height: 66px;
	transform: scale(0.5);
}

.top {
	position: relative;
	width: 24px;
	height: 52px;
	border-radius: 12px;
	background: teal;
}

.decoration {
	position: relative;
	top: 10px;
	left: 10px;
	width: 5px;
	height: 15px;
	border-radius: 3px;
	background: white;
}

.foot1 {
	position: absolute;
	background: teal;
	width: 4px;
	height: 15px;
	top: 51px;
	left: 10px;
}

.foot2 {
	position: absolute;
	background: teal;
	width: 24px;
	height: 4px;
	bottom: 0;
	left: 0;
	border-radius: 3px;
}

.dots {
	position: absolute;
	width: 40px;
	height: 10px;
	opacity: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.dot {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: teal;
}

.checkbox:checked ~ .microphone .dot1 {
	animation: dot-bounce 1s ease-in-out infinite;
}

.checkbox:checked ~ .microphone .dot2 {
	animation: dot-bounce 1s 0.2s ease-in-out infinite;
}

.checkbox:checked ~ .microphone .dot3 {
	animation: dot-bounce 1s 0.4s ease-in-out infinite;
}

.checkbox:checked ~ .microphone .dots {
	animation: dots 1s ease-in-out both;
}

.checkbox:checked ~ .microphone .mic {
	animation: mic 0.8s ease-in-out both;
}

@keyframes dot-bounce {
	0% {
		transform: translateY(5px);
	}

	50% {
		transform: translateY(-5px);
	}

	100% {
		transform: translateY(5px);
	}
}


@keyframes dots {
	0%,
	60% {
		transform: scale(0);
		opacity: 0;
	}

	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes mic {
	0% {
		transform: scale(0.5);
		opacity: 1;
	}

	30% {
		transform: scale(0.65);
		opacity: 1;
	}

	100% {
		transform: scale(0);
		opacity: 0;
	}
}
