.pulse-ring {
	width: 100px;
	height: 100px;
	background: #189bff;
	border: 10px solid #189bff;
	border-radius: 50%;
	position: absolute;
	animation: pulsate infinite 1s;
}

@keyframes pulsate {
	0% {
		-webkit-transform: scale(1, 1);
		opacity: 1;
	}

	25% {
		-webkit-transform: scale(1.1, 1.1);
		opacity: 0.75;
	}

	50% {
		-webkit-transform: scale(1.2, 1.2);
		opacity: 0.50;
	}

	75% {
		-webkit-transform: scale(1.3, 1.3);
		opacity: 0.25;
	}

	100% {
		-webkit-transform: scale(1.4, 1.4);
		opacity: 0;
	}
}
